import React, { useContext } from 'react';
import AlertFormInsideModel from 'molecules/Alert';
import AlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import {
  SELECT_STORE_MSG,
  FIND_NEAR_BY_STORE_CTA,
  NO_LONGER_SERVER_TEXT,
  SORRY_TEXT,
  IS_CLOSED_DUE_TO,
  EMERGENCY_SHUTDOWN,
  TECHNICAL_SHUTDOWN,
  DOORDASH_NOT_SERVICEABLE,
} from 'common/constants/SharedConstants';
import { getTernaryValue } from 'common/utilities/utils';
import { UserOrderContext } from 'context/context';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { MENU_PDP_PAGE_CONSTANTS } from 'organisms/MenuPDPPage/AU/Constants/MenuPDPPageConstants';
const { ITME_NOT_AVAILABLE } = MENU_PDP_PAGE_CONSTANTS;

const AlertContainer = props => {
  const { userOrderState } = useContext(UserOrderContext);
  const { isDeepLinkURL } = userOrderState;
  const {
    handleClose,
    onClickBtnHandler,
    showAlert,
    stoerName,
    storeClosedMsg,
    contentMsg,
    confirmButtonText = FIND_NEAR_BY_STORE_CTA,
    imageSrc = IMAGE_PATH.LocationError,
  } = props;
  const alertLocation = contentMsg && contentMsg === SELECT_STORE_MSG ? 'menuPlp' : '';
  const { emergencyStoreCloseMain } = TRANSLATE_MAPPING_KEY;

  const emergency_Text = () => {
    if (storeClosedMsg?.includes(ITME_NOT_AVAILABLE)) {
      return translateWithI18Next(ITME_NOT_AVAILABLE);
    }
    if (storeClosedMsg?.includes(EMERGENCY_SHUTDOWN) || storeClosedMsg?.includes(TECHNICAL_SHUTDOWN)) {
      return translateWithI18Next(emergencyStoreCloseMain);
    } else if (storeClosedMsg?.includes(DOORDASH_NOT_SERVICEABLE)) {
      return NO_LONGER_SERVER_TEXT;
    } else {
      return getTernaryValue(
        storeClosedMsg,
        ` ${translateWithI18Next(IS_CLOSED_DUE_TO) + ' ' + translateWithI18Next(storeClosedMsg)}`,
        NO_LONGER_SERVER_TEXT,
      );
    }
  };

  return showAlert ? (
    <AlertFormInsideModel
      handleClose={handleClose}
      classNames='plp-warning-msg'
      CartAlertForm={
        <AlertForm
          splitTitle={{
            MSGTXT1: `${translateWithI18Next(SORRY_TEXT)}, `,
            MSGTXT2: ` ${stoerName}`,
            MSGTXT3: emergency_Text(),
          }}
          imageSrc={imageSrc}
          contentMsg={translateWithI18Next(contentMsg)}
          confirmButtonText={translateWithI18Next(confirmButtonText)}
          confirmHandler={onClickBtnHandler}
          buttonContainerClass='deeplink-alert-btn-container alert-btn-container'
          alertLocation={alertLocation}
        />
      }
    />
  ) : null;
};

export default AlertContainer;
