export const isAlphanumeric = (string = '') => {
  return string ? /^[a-zA-Z0-9]+$/.test(string) : false;
};

export const cleanAlphanumeric = (string = '') => {
  return string?.replace(/[^a-zA-Z0-9]/gi, '') || '';
};

export const cleanStringList = (string = '') => {
  return string.split(' ').filter(Boolean).join(' ');
};
