import isEmpty from 'lodash/isEmpty';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib/i18N';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { validateUserDetails, checkoutContactAnalytics } from 'common/utilities/CheckoutUtils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import {
  RNA_PAYMENT_FAILURE,
  RNA_PAYMENT_SUCCESS,
  RNA_CARD_VALIDATION_FAILURE,
} from '@kfc-global/react-shared/config/config.constants';
import { PopUpView as popupViewAnalytics } from 'organisms/MenuPDPPage/Presentation/ReusableComponents/PdpAnalytics';
import PAYMENT_CONSTANTS from 'organisms/Payment/constants/PaymentConstants';
import creditCardType from 'credit-card-type';
import {
  ANALYTICS_SHARED_CONSTANTS,
  PAYMENT_TENDER_TYPES,
  CHECKOUT_CONSTANTS,
  SAVED_CARD_CVV,
  AMEX,
  DATE_FORMATS,
  PAYMENT_GATEWAYS,
  NOT_AVAILABLE,
  TYPE_CHRISTMAS_ORDERING,
} from '../constants/SharedConstants';

import {
  calculatePrice,
  getDiscountAndCoupon,
  getUserType,
  getValueOrDefaultObject,
  toastifyFn,
  redirectToPaymentFailurePage,
  redirectToOrderProcessingPage,
  isPickupDisposition,
  isDeliveryDisposition,
  isCurrentPageRedirectFromRNA,
} from './utils';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { dispatchDeleteKbankSavedCardsApiRequest } from 'organisms/Payment/utils/ThailandPaymentUtils';
import AlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import DeleteCardInsideModel from 'molecules/Alert/Container/AlertFormInsideModel';
import { paymentAnalytics } from './analyticsUtils';
import { payUsingNetbanking } from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { getCartAvailabilityAction } from '@yumbrands/react-shared/redux/Actions/SpecialEventAction';
import { getCheckItemExpiry } from '../../molecules/DynamicMenuAlert/AU/Utils/DynamicMenuAlertUtils';
import {
  getSpecialEventStoreOrderCapacityAction,
  getStoreOrderCapacityAction,
} from '@kfc-global/react-shared/redux/Actions/StoresAction';
import { getEpochTime } from './dayJsUtils';
import { dispatchDeleteDgftCardDetails } from 'organisms/Payment/utils/JapanPaymentUtils';

/*********************************************************************
 *
 *
 *********************************************************************/
export const validatePlaceOrderClick = checkoutDetails =>
  checkoutDetails?.isPaymentTypeSelected &&
  !isEmpty(checkoutDetails?.tender?.name) &&
  !isEmpty(checkoutDetails?.tender?.payload);

/*********************************************************************
 *
 * console.log('updateIsPaymentTypeSelected *****> ', value);
 *********************************************************************/
export const updateIsPaymentTypeSelected = (checkoutDetails, updateCheckoutDetails, value) =>
  updateCheckoutDetails &&
  checkoutDetails?.isPaymentTypeSelected !== value &&
  updateCheckoutDetails({ isPaymentTypeSelected: value });

/*********************************************************************
 *
 *  console.debug('updateTenderDetails ==> ', mapBrainTreeKeysIntoOrderSerKeys[selectedPayment]);
 *********************************************************************/
export const updateTenderDetails = (updateCheckoutDetails, selectedPayment, payload = {}) => {
  updateCheckoutDetails &&
    updateCheckoutDetails({
      isPaymentTypeSelected: true,
      isClickContinuePayment: true,
      tender: {
        name: selectedPayment,
        payload: payload,
      },
    });
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const clearTenderDetails = updateCheckoutDetails =>
  updateCheckoutDetails({
    isPaymentTypeSelected: false,
    tender: { name: '', payload: {} },
    isClickContinuePayment: false,
  });

/*********************************************************************
 * This payment updatePaymentClickHandlerTender will update only for RNA canal to
 * maintain tender value which we are getting from RNA canal.
 *********************************************************************/
export const updatePaymentClickHandlerTender = checkoutDetails =>
  checkoutDetails?.paymentProvider === PAYMENT_GATEWAYS.JAPAN_PAYMENTS &&
  checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CANAL &&
  checkoutDetails?.isRenderFromRNA
    ? checkoutDetails?.tender
    : { name: '', payload: {} };

/*********************************************************************
 * This payment updateShowPlaceOrderButton will update only for RNA canal to
 * hide and show the place order button
 *
 *********************************************************************/
export const updateShowPlaceOrderButton = checkoutDetails =>
  !(
    checkoutDetails?.paymentProvider === PAYMENT_GATEWAYS.JAPAN_PAYMENTS &&
    checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.CANAL &&
    checkoutDetails?.isRenderFromRNA
  );
export const updateCheckoutDetailsWithAddPayment = (checkoutDetails, updateCheckoutDetails, isClickPayOnArrivalarg) => {
  updateCheckoutDetails({
    isClickAddPayment: true,
    tender: updatePaymentClickHandlerTender(checkoutDetails),
    showPlaceOrderButton: updateShowPlaceOrderButton(checkoutDetails),
    openPaymentPopup: true,
    isClickContinuePayment: false,
    isPaymentTypeSelected: false,
    showPreValidationPaymentContactError: false,
    isClickPayOnArrival:
      checkoutDetails?.enablePayOnArrival &&
      checkoutDetails?.enablePayByCashOption &&
      (isPickupDisposition(checkoutDetails?.service) ? isClickPayOnArrivalarg : checkoutDetails?.isClickPayOnArrival),
  });
};
/*********************************************************************
 *
 *
 *********************************************************************/
export const addToPaymentClickHandler = (checkoutDetails, updateCheckoutDetails, isClickPayOnArrivalarg) => {
  if (checkoutDetails?.paymentEnabled && !checkoutDetails?.isClickAddPayment) {
    updateCheckoutDetailsWithAddPayment(checkoutDetails, updateCheckoutDetails, isClickPayOnArrivalarg);
    popupViewAnalytics({ popupTitle: PAYMENT_CONSTANTS?.SELECT_YOUR_PAYMENT });
  } else {
    validateUserDetails(checkoutDetails, updateCheckoutDetails);
  }
};
/*********************************************************************
 *
 *
 *********************************************************************/

export const savedCardAddressUpdate = parms => {
  const { isSavedCard, selectedPaymentDetails, checkoutDetails } = parms;
  const { billingAddressAndSaveCard } = checkoutDetails;
  return isSavedCard
    ? {
        ...selectedPaymentDetails?.billingAddress,
        addressLine: selectedPaymentDetails?.billingAddress?.addressLines[0],
        pincode: selectedPaymentDetails?.billingAddress?.pinCode,
      }
    : { ...billingAddressAndSaveCard, defaultAddressSelected: true };
};
export const changePaymentOptionsHandler = (
  updateCheckoutDetails,
  selectedPaymentDetails,
  isSavedCard,
  checkoutDetails,
) => {
  updateCheckoutDetails({
    isPaymentTypeSelected: true,
    paymentEnabled: true,
    tender: {
      name: isSavedCard ? PAYMENT_TENDER_TYPES?.CREDIT_CARD : selectedPaymentDetails,
      payload: { tender: isSavedCard ? PAYMENT_TENDER_TYPES?.CREDIT_CARD : selectedPaymentDetails },
    },
    cardid: isSavedCard ? selectedPaymentDetails.id : null,
    billingAddressAndSaveCard: savedCardAddressUpdate({ isSavedCard, selectedPaymentDetails, checkoutDetails }),
    savedCardCvv: '',
    cvvError: '',
    isRetryPaymentErrorEnabled: false,
    expiryDate: isSavedCard && selectedPaymentDetails?.expiryDate,
    nameOnCard: isSavedCard && selectedPaymentDetails?.nameOnCard,
    cardNickName: isSavedCard && selectedPaymentDetails?.cardNickName,
    isLastUsedPaymentIsFromSavedCard: false,
    defaultCardId: isSavedCard ? selectedPaymentDetails.id : null,
  });
};

export const getUpdateForCheckoutDetailsAndAnalytics = (checkoutDetails, updateCheckoutDetails) => {
  if (
    !checkoutDetails?.isUpdateCheckoutContactAnalytics &&
    checkoutDetails?.paymentEnabled &&
    checkoutDetails?.isPaymentTypeSelected
  ) {
    const { localized = false, loggedIn = false } = getValueOrDefaultObject(checkoutDetails?.userParams);
    const userType = getUserType(localized, loggedIn);

    const discountLinesObj = getDiscountAndCoupon(checkoutDetails?.cartData);
    const { promoCode = '', amount = '' } = discountLinesObj || {};
    checkoutContactAnalytics(
      { ...checkoutDetails?.cartData, currencyCode: checkoutDetails?.currencyCode },
      checkoutDetails?.currencyConversionFactor,
      checkoutDetails.categoryMapByUrl,
      userType,
      amount,
      promoCode,
    );
    updateCheckoutDetails({ isUpdateCheckoutContactAnalytics: true });
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const isValidCustomerDetails = checkoutDetails => {
  const {
    userFullName,
    userFirstName,
    userLastName,
    userPhone,
    userEmail,
    isEmailMandatoryField,
    enableInValidErrorMessage,
  } = checkoutDetails;
  if (enableInValidErrorMessage === true) {
    return (
      !isEmpty(userFullName?.value) &&
      !isEmpty(userPhone?.value) &&
      (isEmailMandatoryField ? !isEmpty(userEmail?.value) : true)
    );
  } else {
    return (
      !isEmpty(userFullName?.value) &&
      !isEmpty(userFirstName?.value) &&
      !isEmpty(userLastName?.value) &&
      !isEmpty(userPhone?.value) &&
      (isEmailMandatoryField ? !isEmpty(userEmail?.value) : true)
    );
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const getTenderIcon = (tenderlist, tender) => {
  return tenderlist?.items
    ?.filter(cardItem => {
      return cardItem?.fields?.title?.toLowerCase()?.includes(tender?.toLowerCase());
    })
    .map(cardItem => {
      return cardItem?.fields?.icon?.fields?.desktopImage?.fields?.file?.url;
    });
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const getTenderName = (tenderlist, tender) => {
  return tenderlist?.items
    ?.filter(cardItem => {
      return cardItem?.fields?.title?.toLowerCase()?.includes(tender?.toLowerCase());
    })
    .map(cardItem => {
      return cardItem?.fields?.displayName;
    });
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const paymentDetailsAnalytics = checkoutDetails => {
  if (checkoutDetails?.cartData?.foodLines?.length) {
    const { localized = false, loggedIn = false } = getValueOrDefaultObject(checkoutDetails?.userParams);
    const userType = getUserType(localized, loggedIn);

    const discountLinesObj = getDiscountAndCoupon(checkoutDetails?.cartData);
    const { promoCode = '', amount = '' } = discountLinesObj || {};

    const itemsArraysStep3 = [];
    const updated_discount_step3 = (amount / checkoutDetails?.currencyConversionFactor).toFixed(2);
    checkoutDetails?.cartData?.foodLines?.forEach((i, idx) => {
      let price_Amt = calculatePrice(i?.amount, checkoutDetails?.currencyConversionFactor);
      const { id = '', name = '' } = checkoutDetails?.categoryMapByUrl?.[i?.item?.categoryUrl] ?? {};
      itemsArraysStep3.push({
        item_name: i?.item?.dname?.[0]?.value || 'NA',
        item_id: i?.item?.id || 'NA',
        price: parseFloat(price_Amt) || 'NA',
        coupon: promoCode || NOT_AVAILABLE,
        currency: checkoutDetails?.currencyCode,
        discount: parseFloat(updated_discount_step3),
        item_category: name,
        item_list_name: name,
        item_list_id: id,
        index: idx + 1,
        quantity: i?.quantity || 'NA',
      });
    });
    paymentAnalytics(checkoutDetails?.tender?.name, userType, itemsArraysStep3);
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const dispatchPayUsingNetbanking = checkoutDetails => {
  const { dispatch, orderId, tenantId, tender, paymentProvider, cardid, cartDataCustomer, isOneClickEnabled } =
    checkoutDetails;
  const data = {
    orderId,
    tenderType: tender.name,
    vault: true,
    cardId: cardid ? cardid : null,
    Cvv: null,
    isOneClickEnabled: cardid ? isOneClickEnabled : false,
    paymentProvider,
  };
  const payload = {
    data,
    tenantId,
    customerId: cartDataCustomer?.id,
  };
  dispatch(payUsingNetbanking(payload));
};

export const resetPaymentChange = updateCheckoutDetails => {
  updateCheckoutDetails({
    isClickPayOnArrival: false,
    isClickAddPayment: false,
    isPaymentTypeSelected: false,
    tender: {
      name: '',
      payload: {},
    },
    isRetryPaymentErrorEnabled: false,
  });
};

export const updateCheckoutDetaisLoggedIn = (checkoutDetails, updateCheckoutDetails) => {
  const validate = () =>
    checkoutDetails?.userParams?.loggedIn &&
    checkoutDetails?.userFullName?.value !== '' &&
    checkoutDetails?.userEmail?.value !== '' &&
    checkoutDetails?.userPhone?.value !== '' &&
    checkoutDetails?.isCustomTipSelected;

  updateCheckoutDetails({
    isClickAddPayment: false,
    isPaymentTypeSelected: validate(),
    paymentEnabled: validate(),
  });
};

export const deliveryOptionsPayWithCashCheckboxClickHandler = (checkoutDetails, updateCheckoutDetails) => {
  updateCheckoutDetails({
    tender: {
      name: checkoutDetails?.isClickPayOnArrival ? PAYMENT_TENDER_TYPES?.PAY_BY_CASH : '',
      payload: checkoutDetails?.isClickPayOnArrival ? { tender: PAYMENT_TENDER_TYPES?.PAY_BY_CASH } : {},
    },
  });
  if (!checkoutDetails?.isClickPayOnArrival && isDeliveryDisposition(checkoutDetails?.service)) {
    toastifyFn(translateWithI18Next(TRANSLATE_MAPPING_KEY?.PAY_ON_ARRIVAL_TOAST_MESSAGE));
  }
};

export const disableDeliveryOptionsPayWithCashOnArrival = checkoutDetails =>
  (!checkoutDetails?.userParams?.loggedIn &&
    checkoutDetails?.showPreValidationPaymentContactError &&
    !checkoutDetails?.isContactDetailValid) ||
  checkoutDetails?.userOption === CHECKOUT_CONSTANTS?.LEAVE_AT_MY_DOOR ||
  checkoutDetails?.driverdeliveryTip > 0;

export const deliveryOptionsPayWithCashCheckboxHandler = (checkoutDetails, updateCheckoutDetails) => {
  if (
    checkoutDetails?.userOption === CHECKOUT_CONSTANTS?.HAND_IT_TO_ME &&
    checkoutDetails?.enablePayOnArrival &&
    checkoutDetails?.enablePayByCashOption &&
    checkoutDetails?.isClickPayOnArrival
  ) {
    updateCheckoutDetails({
      tender: { name: PAYMENT_TENDER_TYPES?.PAY_BY_CASH, payload: { tender: PAYMENT_TENDER_TYPES?.PAY_BY_CASH } },
    });
    return true;
  }
  return false;
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const updateClickPlaceOrderState = (checkoutDetails, updateCheckoutDetails) => {
  !getCheckItemExpiry(checkoutDetails) &&
    updateCheckoutDetails({ isClickPlaceOrder: validatePlaceOrderClick(checkoutDetails) });
};

/*********************************************************************
 * Called to recheck the order capacity endpoint for 0 values before placing order
 *
 *********************************************************************/
export const checkOrderCapacityRecheck = (
  checkoutDetails,
  updateCheckoutDetails,
  dispositionNew,
  getCartAvailability,
) => {
  const { currentStoreInfo, dispatch, recheckOrderCapacityData, recheckSpecialEventAvailability } = checkoutDetails;
  const storeId = currentStoreInfo?.id || checkoutDetails?.store?.id;
  const dateTimeEpoch = getEpochTime(dispositionNew?.date, DATE_FORMATS.YYYY_MM_DD);

  if (!recheckOrderCapacityData || !recheckSpecialEventAvailability) {
    const payload = {
      storeId,
      dateTimeEpoch,
    };

    if (dispositionNew?.type?.toUpperCase() === TYPE_CHRISTMAS_ORDERING) {
      dispatch(getCartAvailabilityAction()); // Start loading mutation
      getCartAvailability();

      dispatch(getSpecialEventStoreOrderCapacityAction(payload));
    } else {
      dispatch(getStoreOrderCapacityAction(payload));
    }

    updateCheckoutDetails({
      showPlaceOrderLoading: true,
      recheckOrderCapacityData: true,
      recheckSpecialEventAvailability: true,
    });
  } else {
    updateClickPlaceOrderState(checkoutDetails, updateCheckoutDetails);
    updateCheckoutDetails({ recheckOrderCapacityData: false });
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const paymentTrancationSuccessHandler = (props, response = null) => {
  if (isCurrentPageRedirectFromRNA?.(window?.location)) {
    window?.location?.assign(config(RNA_PAYMENT_SUCCESS));
  } else {
    if (response?.status >= 400) {
      redirectToPaymentFailurePage(props);
    } else if (response?.errorDescription) {
      redirectToPaymentFailurePage(props);
    } else {
      redirectToOrderProcessingPage(props);
    }
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const paymentTrancationErrorHandler = (props, error) => {
  const { isCardValidateFailedScreen } = props;
  console.debug('transactionSale error ****=====> ', error);
  if (isCurrentPageRedirectFromRNA?.(window?.location)) {
    isCardValidateFailedScreen
      ? window?.location?.assign(config(RNA_CARD_VALIDATION_FAILURE))
      : window?.location?.assign(config(RNA_PAYMENT_FAILURE));
  } else {
    redirectToPaymentFailurePage(props);
  }
};

export const updateNewAddressHandler = params => {
  const { event, checkoutDetails, updateCheckoutDetails, setShowResults } = params;
  const { billingAddressAndSaveCard } = checkoutDetails;
  updateCheckoutDetails({
    showBillAddressValidationError: false,
    billingAddressAndSaveCard: {
      ...billingAddressAndSaveCard,
      defaultAddressSelected: event.target.checked,
      isBillingAddressFormDetailsValid: event.target.checked,
    },
  });
  setShowResults(event.target.checked);
};

export const updateSaveCardHanlder = params => {
  const { event, checkoutDetails, updateCheckoutDetails, setShowResults } = params;
  const { billingAddressAndSaveCard } = checkoutDetails;
  setShowResults(event.target.checked);
  updateCheckoutDetails({
    billingAddressAndSaveCard: {
      ...billingAddressAndSaveCard,
      enabledSaveCard: event.target.checked,
    },
    showCardNickNameError: false,
  });
};

export const getPlaceOrderValidation = checkoutDetails => {
  const isFormValid = checkoutDetails?.paymentCardDetails?.isFormValid;
  const isBillingAddressFormDetailsValid = checkoutDetails?.billingAddressAndSaveCard?.isBillingAddressFormDetailsValid;
  const defaultAddressSelected = checkoutDetails?.billingAddressAndSaveCard?.defaultAddressSelected;
  if (checkoutDetails?.showPlaceOrderLoading) {
    return true;
  }
  if (
    checkoutDetails?.cardNickNameError ||
    !validateBillingAddress(checkoutDetails) ||
    !validateBillingPostalCode(checkoutDetails)
  ) {
    return false;
  }
  if (isPickupDisposition(checkoutDetails?.service)) {
    return isFormValid && isBillingAddressFormDetailsValid;
  }
  return isFormValid && (isBillingAddressFormDetailsValid || defaultAddressSelected);
};

export const onChangeCVVForm = (event, updateCheckoutDetails, type) => {
  const { name, value } = event?.target;
  validateCvv(value, updateCheckoutDetails, type);
  if (name === SAVED_CARD_CVV) {
    updateCheckoutDetails({
      savedCardCvv: value,
    });
  }
};

export const validateCvv = (value, updateCheckoutDetails, type) => {
  const typeValue = type === AMEX ? '{4}' : '{3}';
  const result = new RegExp('^\\d' + typeValue + '$');
  if (!result.test(value)) {
    updateCheckoutDetails({
      cvvError:
        type === AMEX
          ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.CVV_CREDIT_CARD_4_DIGIT_ERROR)
          : translateWithI18Next(TRANSLATE_MAPPING_KEY?.CVV_CREDIT_CARD_3_DIGIT_ERROR),
    });
  } else {
    updateCheckoutDetails({
      cvvError: '',
    });
  }
};

export const checkPaymentMethod = (allowedTenders, lastUsedPaymentMethod) =>
  allowedTenders?.includes(lastUsedPaymentMethod);

export const getCardType = cardNumber => creditCardType(cardNumber)?.[0]?.type ?? '';

export const getErrorMsg = checkoutDetails =>
  checkoutDetails?.getGiftCardBalance < checkoutDetails?.total / checkoutDetails?.currencyConversionFactor
    ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.GET_CARD_ERROR)
    : null;

export const checkBalance = (checkoutDetails, selectedPaymentDetails) =>
  checkoutDetails?.getGiftCardBalance &&
  checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.GIFT_CARD &&
  selectedPaymentDetails === PAYMENT_TENDER_TYPES?.GIFT_CARD
    ? `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.BALANCE)} : ${checkoutDetails?.getGiftCardBalance}`
    : null;
export const handleDeleteCardModal = (event, updateCheckoutDetails, selectedPaymentDetails) => {
  event.preventDefault();
  event.stopPropagation();
  updateCheckoutDetails({
    deleteCardId: selectedPaymentDetails?.id,
    deleteCardModal: true,
  });
};

export const checkOutDefaultAddressSelected = checkoutDetails =>
  checkoutDetails?.billingAddressAndSaveCard?.defaultAddressSelected;

export const validateBillingAddress = checkoutDetails =>
  checkoutDetails?.billingAddressAndSaveCard?.addressLine !== '' &&
  PAYMENT_CONSTANTS?.ADDRESS_VERIFICATION_REGX.test(checkoutDetails?.billingAddressAndSaveCard?.addressLine);

export const validateBillingPostalCode = checkoutDetails =>
  checkoutDetails?.billingAddressAndSaveCard?.pincode !== '' &&
  checkoutDetails?.postalCodeRegex?.test(String(checkoutDetails?.billingAddressAndSaveCard?.pincode));

export const deleteCardHandler = (checkoutDetails, updateCheckoutDetails) => {
  checkoutDetails?.paymentProvider === PAYMENT_GATEWAYS?.JAPAN_PAYMENTS
    ? dispatchDeleteDgftCardDetails(checkoutDetails, updateCheckoutDetails)
    : dispatchDeleteKbankSavedCardsApiRequest(checkoutDetails, updateCheckoutDetails);
};

export const deleteCardModalHandler = (checkoutDetails, updateCheckoutDetails) => {
  const { DELETE_CARD, CANCEL, CARD_DELETE_CONFIRMATION } = TRANSLATE_MAPPING_KEY;
  const CARD_DELETE_CONSTANTS = {
    CONFIRM_CARD_DELETE_TEXT: {
      MSGTXT1: translateWithI18Next(CARD_DELETE_CONFIRMATION),
    },
  };
  const handleClose = () => {
    updateCheckoutDetails({
      deleteCardModal: false,
    });
  };
  return (
    <DeleteCardInsideModel
      handleClose={handleClose}
      CartAlertForm={
        <AlertForm
          splitTitle={CARD_DELETE_CONSTANTS.CONFIRM_CARD_DELETE_TEXT}
          imageSrc={IMAGE_PATH.PictogramAlertIcon}
          altText={'delete-card'}
          cancelButtonText={translateWithI18Next(CANCEL)}
          confirmButtonText={translateWithI18Next(DELETE_CARD)}
          confirmHandler={() => deleteCardHandler(checkoutDetails, updateCheckoutDetails)}
          cancelHandler={handleClose}
        />
      }
    />
  );
};
