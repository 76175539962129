const ORDER_HISTORY = {
  CLOSED: 'CLOSED',
  SEARCH_IN_CART: 'SEARCH_IN_CART',
  CANCEL: 'cancelButtonText',
  SUBMIT: 'Submit',
  OCCASSOIN_HEADING: 'occasionGiveName',
  OREDER_PLACEHOLDER: 'nameThisOrder',
  YOUR_ORDER: 'yourOrder',
  DELIVER_TO: 'deliveryTo',
  PAYMENT: 'payment',
  ORDER_FROM: 'orderFrom',
  GET_DIRECTION: 'getDirection',
  SUBTOTAL: 'subTotal',
  GST: 'GST',
  REST_HANDLING: 'cartIncluTax',
  DISCOUNT: 'DiscountText',
  GIFT_CARD: 'giftCard',
  TOTAL: 'total',
  COMPLETED: 'completed',
  NO_ORDER_MSG: 'noOrderFor12',
  REFUND_MSG: 'refundMessage',
  HAVE_A_QUESTION: 'haveAQuestion',
  CONNECT_WITH_SPECIALIST: 'connectWithASpecialist',
  YOUR_PAST_ORDER: 'yourPastOrder',
  LOOKING_FOR_OFFER: 'lookingForSpecificOrder',
  ORDER_HISTORY_TAG: 'orderHistory',
  REFUND_INIT: 'refund-initiated',
  SCHEDULE: 'scheduled',
  REORDER_TEXT: 'reorderBtn',
  GOOD_EVE: '',
  MSG: 'message',
  MY_FAV_MENU: 'myFavorite',
  ADDRESS: 'address',
  ACCOUNT_SETTING: 'accoundSetting',
  SIGN_OUT: 'signOut',
  REFUND_INIT_TEXT: 'refundInitiated',
  ORDER: 'order',
  TRACK_YOUR_ORDER: 'trackYourOrder',
  AT: 'at',
  GET_HELP: 'getHelp',
  LOAD_MORE: 'loadMore',
  VIEWED: 'Viewed',
  OF: 'of',
  ORDER_LOOKUP: 'orderLookup',
  VIEW_MENU: 'viewMenu',
  ADD_TO_OCCASION: 'addToOccasion',
  VIEW_RECEIPT: 'viewReceipt',
  SAVE: 'save',
  EDIT_OCCASSION_DIALOG_TITLE: 'Edit Occasion Name',
  OCCASSION_LABEL: 'Occasion Name',
  SAME_OCCASSIONNAME_ERROR: 'Occasion name already exists',
  EMPTY_OCCASSION_FIELD_ERROR: 'Occasion name should not be empty',
  ADD_TO_CART_OCCASSION: 'Add to Cart',
  STORE_CLOSED_BUTTON_TEXT: 'storeClosedButtonText',
  ITEM_UNAVAILABLE: 'Unavailable',
  VIEW_ORDER_HISTORY_BTN: 'View Order History',
  MY_OCCASSION_TEXT: 'My Occasions',
  MY_OCCASSION_UNAVAILABLE_TITLE: `You haven't created any occasions yet`,
  DELETE_OCCASSION_TEXT: 'Delete this Occassion',
  REORDER_OCCASSION: 'Reorder',
  MY_FAVOURITE_MENU_TITLE_TEXT: 'My Favorite Menu',
  MY_FAVOURITE_NO_ITEMS_TEXT: `You haven't favorited any items yet`,
  MY_FAVOURITE_BROWSE_MENU: 'Browse the menu',
  EDIT_ITEM_TEXT: 'editItemText',
  MORNING: 'morning',
  NOON: 'noon',
  EVENING: 'evening',
  ACCOUNT_FAV_PAGE: 'Account - Favorite Orders',
  KFC_LOVER: 'KFC LOVER',
  TODAY_AT: 'todayAt',
  ADD_ITEM_TO_CART_TEXT: 'addItemToCartText',
  LARGE_CARRY_BAG: 'largeCarryBag',
  ADD_HOPE_TEXT: 'addhope',
  NAME_EXIST: 'nameExistMsg',
  OCCASION_NAME_VALIDATION: 'OccasionNameValidation',
  UNFAVOURITE_ITEM_MESSAGE: 'unFavoriteItemMessage',
  ACCOUNT_ORDERS: 'Account - Order History',
  TRACK_ORDER: 'trackOrder',
  CANCELLED: 'Cancelled',
  DELIVERED: 'Delivered',
  PICK_UP: 'PICK UP',
};
export default ORDER_HISTORY;

export const ACCOUNT_SETTINGS = {
  ACCOUNT_SETTINGS_TEXT: 'accoundSetting',
  DELETE_ACCOUNT_TEXT: 'deleteAccount',
  EDIT_PERSONAL_INFO: 'editPersonalInfo',
  FIRST_NAME_TEXT: 'firstNamePlaceholder',
  LAST_NAME_TEXT: 'lastNamePlaceHolder',
  EMAIL_TEXT: 'emailPlaceholder',
  PHONE_NUMBER_TEXT: 'phonePlaceholder',
  CANCEL: 'cancelButtonText',
  SAVE: 'save',
  PERSONAL_INFO_TEXT: 'personalInfo',
  EDIT: 'edit',
  DELETE_ACCOUNT_MESSAGE: 'areYouSureToDeleteAccountText',
  CONFIRM: 'confirm',
  emailHelpText: 'emailHelpText',
  phoneHelpText: 'phoneHelpText',
  firstNameHelpText: 'firstNameHelpText',
  lastNameHelpText: 'lastNameHelpText',
};

export const preferencesData = [
  {
    mainText: 'Keep me up to date with marketing emails from KFC',
    subText: '',
    checked: true,
  },
  {
    mainText: 'Editor’s Pick & Customer Favorite ',
    subText: 'Receive Editor updates on new and upcoming releases and the best reviews from customers like you',
    checked: true,
  },
  {
    mainText: 'KFC news and announcements ',
    subText: 'Get occasional emails about site improvements or changes ',
    checked: true,
  },
  {
    mainText: 'Do not sell my personal information',
    subText: '',
    checked: true,
  },
];

export const COMMUNICATION_PREFERENCES_CONSTANTS = {
  LEAVE: 'leaveText',
  SAVE: 'save',
};

export const HTTPS_URL = 'https://';
