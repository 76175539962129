export const MENU_PLP_CONSTANT = {
  CAROUSAL_HEADER: 'upSellTitle',
  PRODUCT_VIEW_TEXT: 'view',
  NAV_TITLE: 'menuTitle',
  MENU_CARD: {
    TIMER: 'timer',
    CURRENCY: '₹',
    SERVES: '  ',
    VEG: 'veg',
    NON_VEG: 'nonveg',
    EXCLUSIVE_DEAL: 'exclusiveDeal',
    NEW: 'new',
    TOP_SELLER: 'topSeller',
  },
  CARD_TYPE: {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
    CAROUSAL: 'carousal',
  },
  SERVICE_CHANNEL: 'web',
  ADD_NEW_PRODUCT: 'Add new product',
  UPDATE_CART: 'Update Cart',
  EDIT_SELECTIONS: 'editSelectionsBtnText',
  EDIT_QUANTITY: 'editSelections',
  SELECT_QUANTITY: 'selectQuantity',
  PROMOS_REWARDS: 'promosandrewards',
  OFFERS_DEALS: 'Offers and Deals',
};
export const FILTER_BY = 'filterBy';
export const DIETARY = 'dietary';
export const SORT_BY = 'sortBy';
export const SORT_BY_TEXT = 'SORT BY';
export const CLEAR_ALL = 'clearAll';
export const CLEAR_SEARCH = 'clearSearch';
export const YOUR_SEARCH_TEXT = 'yourSearch';
export const TURNED_NO_RESULTS_TEXT = 'turnedNoResult';
export const BACK_TO_MENU = 'backToMenu';
export const WE_FOUND_TEXT = 'weFound';
export const SEARCH_OUR_MENU_TEXT = 'Search our menu';
export const APPLY_TEXT = 'apply';
export const ALERT_TEXT = 'multipleCustomizationText';
export const ALERT_CONFIRM_TEXT = 'Yes';
export const ALERT_CANCEL_TEXT = 'No';

export const SORRY = 'Sorry';
export const CURRENTLY_CLOSED = 'is currently closed.';
export const CC_MESSAGE = 'Please select a different store or order during working hours.';
export const KEEP_BROWSING = 'Keep Browsing';
export const FIND_OPEN_KFC = 'Find Open KFC';
export const RESULTS = 'results';
export const INDIA = 'INDIA';
export const CLOSED = 'CLOSED';
export const CURRENTSTOREINFO = 'currentStoreInfo';
export const VIEW_ITEM = 'view_item';
export const CATEGORY_ID = 'CAT86';
export const ENTER = 'Enter';
export const TIMER_I18Next = 'timer';
export const SORRY_THIS_FOOD = 'sorryThisFood';
export const IS = 'is';
export const FOR = 'for';
export const NOT_AVAILABLE = 'notavailable';
export const SHOW_PDP_ALERT_POPUP = 'pdpAlertPopup';
export const NO_OF_ITEMS = 'noOfItems';
