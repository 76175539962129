/* To get Adyen payment methods type*/
import { config } from '@kfc-global/react-shared/config/config.utils';
import Strings from '@kfc-global/react-shared/constants/Strings';
import PAYMENT_CONSTANTS from '../constants/PaymentConstants';
import { ADYEN_ENVIRONMENT } from '@kfc-global/react-shared/config/config.constants';
import { submitAdditionalAdyenPaymentRequest } from '@kfc-global/react-shared/redux/Actions/AdyenPaymentAction';
import { redirectToOrderProcessingPage, redirectToPaymentFailurePage } from 'common/utilities/utils';
import { addRemoveInclass } from 'common/utilities/CheckoutUtils';

export const getAdyenPaymentTenderType = adyenPaymentMethod =>
  adyenPaymentMethod?.type === PAYMENT_CONSTANTS?.ADYEN_PAYMENT_TYPE.PAYMENT_TYPE
    ? Strings.creditCard
    : adyenPaymentMethod?.type;

export const getAdyenPaymentsEnvironment = () => {
  return config(ADYEN_ENVIRONMENT);
};

export const additionalAdyenDetails = (state, updateCheckoutDetails) => {
  const { details } = state?.data;
  return updateCheckoutDetails({ additionalDetails: details });
};

export const submitAdyenAdditionData = params => {
  const { tenantId, customerId, postBody, dispatch, AUTHORISED, props } = params;
  dispatch(
    submitAdditionalAdyenPaymentRequest({
      tenantId,
      customerId,
      postBody,
      successCallback: additionalAdyenResponse => {
        addRemoveInclass(false);
        const submitAdditionalResponse = JSON.parse(additionalAdyenResponse?.response);
        if (submitAdditionalResponse?.resultCode === AUTHORISED) {
          redirectToOrderProcessingPage(props);
        } else {
          redirectToPaymentFailurePage(props);
        }
      },
      errorCallback: response => {
        addRemoveInclass(false);
        console.log('response--', response);
        redirectToPaymentFailurePage(props);
      },
      resultCode: params?.resultCode, // only for utc
    }),
  );
};

export const getAidenPostData = (checkoutDetails, userParams) => {
  const {
    browserInfo,
    customerId,
    currencyCode,
    orderId,
    paymentMethod,
    userFirstName,
    userLastName,
    userPhone,
    userEmail,
    total,
    tenantId,
    tender,
  } = checkoutDetails;
  return {
    orderId,
    tenderType: tender?.name,
    paymentMethod,
    browserInfo,
    amount: {
      currency: currencyCode,
      value: total,
    },
    firstName: userFirstName?.value,
    lastName: userLastName?.value,
    phone: userPhone?.value,
    email: userEmail?.value,
    'x-is-registered-user': userParams?.loggedIn,
    'x-tenant-id': tenantId,
    'x-user-id': customerId,
    'merchant-account': checkoutDetails?.merchantAccount,
    'app-source': 'web',
    os: getOperatingSystem(),
    OsVersion: getOSVersion(),
    AppVersion: process.env.REACT_APP_PWA_VERSION_STRING,
  };
};

export const getOperatingSystem = () => {
  let userAgent = window.navigator.userAgent;
  let platform = window.navigator.platform;
  let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'Windows';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (/Linux/.test(platform)) {
    return 'Linux';
  }
  return 'Windows';
};

export const getOSVersion = () => {
  let userAgent = window.navigator.userAgent;
  let osVersion = '';
  if (/Windows/.test(userAgent)) {
    osVersion = userAgent.match(/Windows NT \d+\.\d+/)[0];
  }
  return osVersion;
};
