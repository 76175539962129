import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MaterialTextInput } from 'atoms/MaterialInput';
import { ButtonComp } from 'atoms/Buttons';
import ErrorAlertFormInsideModel from 'molecules/Alert/Container/AlertFormInsideModel';
import CartAlertForm from 'molecules/Alert/Presentation/Alert/AlertForm';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { checkGiftCardBalance } from '@kfc-global/react-shared/redux/Actions/GiftCheckBalanceAction';
import { GIFTCARD_CONSTANTS } from 'organisms/GiftCard/Constants/giftcardConstants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { isMobileDevice } from 'common/utilities/utils';
import { embeddedErrorMessageAnalyticFn } from './CheckoutFn';

export default function PaymentFormGiftCard({ setFormPayload, setIsValid, updateCheckoutDetails, checkoutDetails }) {
  const { formPayload } = checkoutDetails || {};
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { Ellipse } = IMAGE_PATH;
  const [cardNumberState, setCardNumberState] = useState({
    value: '',
    errorMessage: '',
    error: false,
  });
  const [pinNumberState, setPinNumberState] = useState({
    value: '',
    errorMessage: '',
    error: false,
  });

  const [balanceState, setBalanceState] = useState('');
  const [cartBalance, setCartBalance] = useState(0);
  const [balanceErrorFlag, setBalanceErrorFlag] = useState(false);
  const [balanceErrorMsg, setBalanceErrorMsg] = useState('');
  const [isMobile, setIsMobile] = useState(isMobileDevice(window.innerWidth));

  const [checkBalancePayload, setCheckBalancePayload] = useState({});

  const { CARD_NUMBER_REGEX } = GIFTCARD_CONSTANTS;

  const { tenantReducer, profileReducer, cartReducer } = useSelector(data => {
    return data;
  });

  const { response } =
    useSelector(data => {
      return data.createBalanceReducer;
    }) || {};

  const dispatch = useDispatch();
  const { tenantId } = tenantReducer || {};

  const getBalance = () => {
    let balance = 0;
    let total = cartReducer?.cartData?.total;
    let conversionFactor = tenantReducer?.currencyConversionFactor;
    if (total || conversionFactor) {
      return total / conversionFactor;
    } else {
      return balance;
    }
  };

  const windowResized = () => {
    const isMobileView = isMobileDevice(window.innerWidth);
    setIsMobile(isMobileView);
  };

  useEffect(() => {
    let cartBalanceLocal = getBalance();
    setCartBalance(cartBalanceLocal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', windowResized);
  }, [isMobile]);

  useEffect(() => {
    setCardNumberState({
      value: '',
      errorMessage: '',
      error: false,
    });
    setPinNumberState({
      value: '',
      errorMessage: '',
      error: false,
    });
    setBalanceState('');
  }, []);

  useEffect(() => {
    let customerId = profileReducer?.customerId;
    const reqPayload = {
      customerId,
      tenantId,
      body: {
        cardNumber: cardNumberState.value,
        cardPin: pinNumberState.value,
      },
    };

    if (
      tenantId &&
      tenantId !== undefined &&
      cardNumberState.value !== undefined &&
      pinNumberState.value !== undefined &&
      cardNumberState.value.length > 15 &&
      pinNumberState.value.length > 5
    ) {
      setCheckBalancePayload(reqPayload);
    } else {
      setBalanceState('');
      updateCheckoutDetails?.({
        isValid: false,
      });

      //Can create payload for invalid cards as we have separate error popup to display invalid card
      setCheckBalancePayload(reqPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNumberState, pinNumberState]);

  useEffect(() => {
    let responseLocal = response;
    if (responseLocal) {
      getCheckBalanceResponse(responseLocal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    embeddedErrorMessageAnalyticFn(
      translateWithI18Next(GIFTCARD_CONSTANTS.SELECT_YOUR_PAYMENT_SCREEN_HEADER),
      cardNumberState?.error,
      cardNumberState?.errorMessage,
    );
  }, [cardNumberState.errorMessage, cardNumberState.error]);

  useEffect(() => {
    embeddedErrorMessageAnalyticFn(
      translateWithI18Next(GIFTCARD_CONSTANTS.SELECT_YOUR_PAYMENT_SCREEN_HEADER),
      pinNumberState?.error,
      pinNumberState?.errorMessage,
    );
  }, [pinNumberState.errorMessage, pinNumberState.error]);

  const styleForMaterialTextInput = error => {
    return error ? 'formElement-error' : 'formElement';
  };

  const errorStyleForMaterialTextInput = error => {
    return error ? 'text-danger' : ' ';
  };

  const getCardNumberData = (cardNumberObject, inputCardNumber) => {
    let cardObj = { ...cardNumberObject };
    cardObj.value = inputCardNumber;
    if (!inputCardNumber) {
      cardObj.error = true;
      cardObj.errorMessage = translateWithI18Next(GIFTCARD_CONSTANTS.GIFTCARD_ERROR);
    } else if (inputCardNumber.length < 16 || isNaN(inputCardNumber)) {
      cardObj.error = true;
      cardObj.errorMessage = translateWithI18Next(GIFTCARD_CONSTANTS.INVALID_CARD_NUMBER);
    } else {
      cardObj.error = false;
      cardObj.errorMessage = '';
    }
    return cardObj;
  };

  const getPinNumberData = (pinNumberObject, inputPinNumber) => {
    let pinObj = { ...pinNumberObject };
    pinObj.value = inputPinNumber;
    if (!inputPinNumber) {
      pinObj.error = true;
      pinObj.errorMessage = translateWithI18Next(GIFTCARD_CONSTANTS.PINNUMBER_ERROR);
    } else if (inputPinNumber.length < 6 || isNaN(inputPinNumber)) {
      pinObj.error = true;
      pinObj.errorMessage = translateWithI18Next(GIFTCARD_CONSTANTS.INVALID_PIN_NUMBER);
    } else {
      pinObj.error = false;
      pinObj.errorMessage = '';
    }
    return pinObj;
  };

  const getBalanceData = (inputBalance, cartBalanceVal) => {
    if (cartBalanceVal > inputBalance) {
      setBalanceErrorFlag(true);
      let balanceErrorMsgVal = translateWithI18Next(GIFTCARD_CONSTANTS.INSUFFICIENT_BALANCE);
      setBalanceErrorMsg(balanceErrorMsgVal);
      updateCheckoutDetails?.({
        isValid: false,
      });
    } else {
      setBalanceErrorFlag(false);
      setBalanceErrorMsg('');
      updateCheckoutDetails?.({
        isValid: true,
        openPaymentPopup: true,
      });
    }
  };

  const getUserData = (name, callBack) => {
    name && callBack();
  };

  const updateUserDetails = event => {
    const inputValue = event.target.value;
    const inputName = event.target.name;

    getUserData(inputName === 'cardNumber', () =>
      setCardNumberState({
        ...getCardNumberData(cardNumberState, inputValue),
      }),
    );

    getUserData(inputName === 'pinNumber', () =>
      setPinNumberState({
        ...getPinNumberData(pinNumberState, inputValue),
      }),
    );
  };

  const handleCardNumberValidation = e => {
    if (!CARD_NUMBER_REGEX.test(e.key)) {
      e.preventDefault();
    }
  };

  const inputChangeHandler = event => {
    const { name, value } = event.target;
    if (name === 'cardNumber') {
      setCardNumberState({ ...cardNumberState, [name]: (value || '').trim() });
    } else {
      setPinNumberState({ ...pinNumberState, [name]: (value || '').trim() });
    }
  };

  const onCheckBalanceClick = () => {
    dispatch(checkGiftCardBalance(checkBalancePayload));
  };

  const getCheckBalanceResponse = responseData => {
    if (responseData.cardStatus === GIFTCARD_CONSTANTS.ACTIVE) {
      getBalanceData(responseData.cardBalance, cartBalance);
      setBalanceState(responseData.cardBalance);
      updateCheckoutDetails?.({
        formPayload: {
          ...formPayload,
          AdditionalAttributes: {
            GiftCardNumber: cardNumberState.value,
            GiftCardPin: pinNumberState.value,
          },
        },
      });
    } else if (responseData.cardStatus === GIFTCARD_CONSTANTS.INACTIVE) {
      setBalanceState('');
      //2. If status is InActive, open error modal
      setIsErrorModalOpen(true);
      updateCheckoutDetails?.({
        isValid: false,
      });
      setFormPayload?.({});
    }
  };

  const onCancelClickHandler = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <React.Fragment>
      <div className='col-12 payment-form-credit-card-container'>
        {!isMobile && <p className='payment-mandatory-note'>{translateWithI18Next('mandatorynote')}</p>}
        <div className='giftcardRow'>
          <div className='col-12'>
            <MaterialTextInput
              className={styleForMaterialTextInput(cardNumberState.error)}
              type='text'
              maxLength={16}
              onKeyPress={e => handleCardNumberValidation(e)}
              required
              name='cardNumber'
              data-testid='cardNumber'
              placeholder={translateWithI18Next(GIFTCARD_CONSTANTS.CARD_NUMBER_PLACEHOLDER)}
              //onChange={event => inputChangeHandler(event)}
              onChange={updateUserDetails}
              value={cardNumberState.value}
              inputTestId='gc-number-handler'
              autoComplete='gc-number'
            ></MaterialTextInput>
            <div id='cardNumber' className={errorStyleForMaterialTextInput(cardNumberState.error)}>
              {cardNumberState.error && <img src={Ellipse} alt='' className={`mr-2`} />}
              <span role='alert'>{cardNumberState.errorMessage}</span>
            </div>
          </div>
        </div>
        {/* Pin Number and Balance */}
        <div className='giftcardRow'>
          <div className='col-6'>
            <MaterialTextInput
              className={styleForMaterialTextInput(pinNumberState.error)}
              type='password'
              maxLength={6}
              required
              name='pinNumber'
              data-testid='pinNumber'
              placeholder={translateWithI18Next(GIFTCARD_CONSTANTS.PIN_NUMBER_PLACEHOLDER)}
              //onChange={event => inputChangeHandler(event)}
              onChange={updateUserDetails}
              value={pinNumberState.value}
              inputTestId='pin-number-handler'
              autoComplete='pin-number'
            ></MaterialTextInput>
            <div id='pinNumber' className={errorStyleForMaterialTextInput(pinNumberState.error)}>
              {pinNumberState.error && <img src={Ellipse} alt='' className={`mr-2`} />}
              <span role='alert'>{pinNumberState.errorMessage}</span>
            </div>
          </div>
          {/**-------------- */}
          <div className='col-6'>
            <MaterialTextInput
              className={styleForMaterialTextInput(balanceErrorFlag)}
              type='text'
              disabled
              required
              name='balance'
              data-testid='balance'
              placeholder={translateWithI18Next(GIFTCARD_CONSTANTS.BALANCE_PLACEHOLDER)}
              onChange={event => inputChangeHandler(event)}
              value={balanceState}
              inputTestId='balance-handler'
              autoComplete='balance'
            ></MaterialTextInput>
            <div id='balance' className={errorStyleForMaterialTextInput(balanceErrorFlag)}>
              {balanceErrorFlag && <img src={Ellipse} alt='' className={`mr-2`} />}
              <span role='alert'>{balanceErrorMsg}</span>
            </div>
          </div>
        </div>

        {/* Check Balance Button */}
        <div className='giftcardRow  check-balance'>
          <div className='col-12'>
            <ButtonComp
              className='button  whiteButton blackBorder add-more-menu'
              data-testid='button'
              onClick={onCheckBalanceClick}
              ariaLable={translateWithI18Next(GIFTCARD_CONSTANTS.CHECKBALANCE_MODAL.CHECKBALANCE_BUTTON)}
            >
              {translateWithI18Next(GIFTCARD_CONSTANTS.CHECKBALANCE_MODAL.CHECKBALANCE_BUTTON)}
            </ButtonComp>
          </div>
        </div>

        {isErrorModalOpen && (
          <div className='checkBal-errorModal'>
            <ErrorAlertFormInsideModel
              CartAlertForm={
                <CartAlertForm
                  title={translateWithI18Next(GIFTCARD_CONSTANTS.CHECKBALANCE_MODAL.INVALID_MODALTITLE)}
                  contentMsg={translateWithI18Next(GIFTCARD_CONSTANTS.CHECKBALANCE_MODAL.INVALID_MODALCONTENT)}
                  cancelHandler={onCancelClickHandler}
                  cancelButtonText={translateWithI18Next(GIFTCARD_CONSTANTS.CHECKBALANCE_MODAL.INVALID_OKBUTTON)}
                />
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
